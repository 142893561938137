import React, { useState, useEffect } from "react";
import { db } from "../firebase-config.js";
import { ReactComponent as Logo } from '../svg/s2u_logo.svg';
import { ReactComponent as SearchIc } from '../svg/search-ic.svg';
import ResultBox from "../Components/ResultBox.js";
import './UserSearch.scss';


export default function UserSearch() {

  // 검색 대상
  const [selectedOption , setSelectedOption] = useState('thaiDel')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    event.target.value === 'thaiDel' ? setPlaceholder('type your phone number') : setPlaceholder('type your tracking code')
  };

  const [searchCode, setSearchCode] = useState(``);

  // db 불러오기
  const [results, setResults] = useState([]);

  // 결과값
  const [found, setFound] = useState();

  // placeholder
  const [placeholder , setPlaceholder] = useState('type your phone number')


  useEffect(()=>{

    if (selectedOption === 'korDel') {

      // 한국 택배 DB 불러오기
      db.collection('korDel')
        .get()
        .then((result)=>{
          let tempResult = []
  
          result.forEach((doc) => {
            tempResult.push(doc.data())
          })
  
          setResults(tempResult)
        })


    } else {

      // 태국 택배 DB 불러오기
      db.collection('thaiDel')
        .get()
        .then((result)=>{
          let tempResult = []

          result.forEach((doc) => {
            tempResult.push({
              phoneNumber : doc.id,
              trackings : doc.data().trackings
            })
          })

          setResults(tempResult)

        })

    }

    setFound()

    

  },[selectedOption])
  



  // 검색 기능
  function Search() {

    if (searchCode.length > 5) {
      
      // =======================================================
      // ======================  검색 로직  ======================


      setFound(
        <ResultBox 
          found={false} 
          errType='notFound'
          delType={selectedOption}
    
          // 한국 택배정보
          kor_code={searchCode}
        />)

  
      // 코드 조회

      if (selectedOption === 'korDel') {
        // 한국 택배 조회

        results.forEach((doc) => {
              
          const codes = doc.codes
          
          if ( codes.includes(searchCode) ) {
            
            const timestamp = doc.date.toDate()
            const year = timestamp.getFullYear()
            const month = timestamp.getMonth()+1
            const date = timestamp.getDate()
    
            setFound(<ResultBox 
              
              found={true} 
              delType={selectedOption}
    
              // 한국 택배정보
              kor_arrive_date={`${year}-${month}-${date}`}
              kor_code={searchCode}
  
            />)
          }
        })

        console.log('한국 택배 조회')
        
      } else {
        // 태국 택배 조회
        results.forEach((doc) => {

          if (doc.phoneNumber === searchCode ) {

            // 전화번호가 있는 경우
            const result = {
              phoneNumber : searchCode,
              trackings : doc.trackings
            }
            console.log('trackings : ', doc.trackings)

            setFound(<ResultBox 
              
              found={true} 
              delType={selectedOption}
              thaiResult= {result}

            />)

          }
        })

        
      }

      // ===========================================================
      // ===========================================================

      } else {

        // 검색 텍스트 너무 짧을 경우

        setFound(<ResultBox 
          found={false} 
          errType='tooShort'
          delType={selectedOption}
    
        />)
      }
  }

  // input form sync
  function inputSync(e) {
    setSearchCode(e.target.value)
  }
  
  // 엔터로 검색
  function EnterEvent(e) {
    // 엔터
    if(e.key==='Enter'){    
      Search();
    }   
  }


  


  
  return (
    <div className="containor">
      <header className="search-area">

        {/* 로고 영역 */}
        <div className="logo-area">
          <a href="/">
            <Logo width="118" height='41' />
          </a>

          <div className="sub-title">Search your delivery in Korea & Thailand </div>

          <div className="del-type-radio-containor">
                    <label className={selectedOption==='thaiDel' ? 'selected' : ''}>
                        <input 
                        type="radio"
                        value="thaiDel"
                        checked={selectedOption === "thaiDel"}
                        onChange={handleOptionChange}
                        />
                        🇹🇭 Thai
                    </label>
                    <label className={selectedOption==='korDel' ? 'selected' : ''}>
                        <input 
                        type="radio"
                        value="korDel"
                        checked={selectedOption === "korDel"}
                        onChange={handleOptionChange}
                        />
                        🇰🇷 Kor
                    </label>
                </div>
        </div>

        {/* 검색 박스 */}
        <div className="search-box">

          <input 
            type={'text'}
            onChange={inputSync} 
            onKeyDown={EnterEvent}
            placeholder={placeholder}
          />

          {/* 검색 버튼 */}
          <button onClick={Search}> 
            <SearchIc />
          </button>

        </div>
      

      </header>

      <div className="result-area">
        
        {found}
        
      </div>
    </div>
  );
}

