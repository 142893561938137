import React from "react";
import { ReactComponent as NoResult } from '../svg/no_result.svg';
import './ResultBox.scss';



function KeyValue( props ) {
    
    return (
        <div className="key-value-line">     
            <div className="title">{props.title}</div>
            <div className="value">{props.value}</div>
        </div>
    )
}


// 결과 있는 경우
function ResultFound(props) {

    return (
        <div className="result-box-containor-found">

            {props.delType === 'korDel' ? 

                // 한국내 택배
                <div className="result-box-containor-contents">
                    <div className="img-containor">
                        <img className="box-image" src="img/img_box.png" alt="" />
                    </div>
                    <div className="result-title">
                        Your parcel <br/>
                        has been arrived !
                    </div>

                    <KeyValue title="Arrive date" value={props.kor_arrive_date}  />
                    <KeyValue title="Tracking code" value={props.kor_code}  />
                </div> :

                // 태국내 배송
                <div className="result-box-containor-contents">

                    <div className="phone-number-title" >phone number</div>
                    <div className="phone-number" >{props.result.phoneNumber}</div>

                    <h1>🚚</h1>

                    {props.result.trackings.map((record) => {

                        const recordDate = record.update.toDate().toISOString().substr(0, 10)
                        
                        return (
                            <div className="record-box">
                                <KeyValue title="Date" value={recordDate}  />
                                <KeyValue title="Code" value={record.code}  />
                                <KeyValue title="Delivery Company" value={record.delCom}  />
                            </div>
                        )

                    })}
                </div>
            }
        </div>
    )
}

// 결과 없는 경우
function ResultNotFound(props) {

    return (
        <div className="result-box-containor-not-found">
            
            { props.errType === 'notFound' ?

                <div>
                    <NoResult />
                    <div className="not-found-message">
                        no result with <br/>
                        '{props.kor_code}'
                    </div> 
                </div> :
                <div className="not-found-message">
                    Code is too short <br/>
                    Please type longer than 5 letters
                </div>
            }
        </div>
    )
}


export default function ResultBox(props) {

    return (
        <div className="result-box-outer">
            {props.found ? 
            
            <ResultFound 
                kor_arrive_date={props.kor_arrive_date} 
                kor_code={props.kor_code}
                delType={props.delType}
                result={props.thaiResult}
                /> : 

            <ResultNotFound
                errType={props.errType}
                kor_code={props.kor_code}
            />}
        </div>
    )
}

