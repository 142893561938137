// import { initializeApp } from "firebase/app";
// import {getFirestore} from "@firebase/firestore";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
 
const firebaseConfig = {
  
  // apiKey: process.env.REACT_APP_APIKEY ?? "",
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN ?? "",
  // databaseURL: process.env.REACT_APP_DATABASE_URL ?? "",
  // projectId: process.env.REACT_APP_PROJECT_ID ?? "",
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET ?? "",
  // messagingSenderId: process.env.REACT_APP_MESSAGIN_ID ?? "",
  // appId: process.env.REACT_APP_APP_ID ?? "",
  // measurementId: process.env.REACT_APP_MEASUREMENT_ID ?? "",

  apiKey: "AIzaSyApjAW_9KRRdlE_56-Tm_oA2TBL_ENKgUU",
  authDomain: "seoul2u-tracking.firebaseapp.com",
  databaseURL: "seoul2u-tracking",
  projectId: "seoul2u-tracking",
  storageBucket: "seoul2u-tracking.appspot.com",
  messagingSenderId: "361216495630",
  appId: "1:361216495630:web:17fb5ae28af4db4ac6ec5f",
  measurementId: "G-GKVW6M5CKL"
};


firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { db };