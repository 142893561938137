import React from "react";
import { Routes, Route } from 'react-router-dom';
import UserSearch from "./Pages/UserSearch.js"
import Admin from "./Pages/Admin.js"
import './App.scss';


function App() {

  return (
    <div>
        <Routes>
          <Route path="/" element={<UserSearch />} />
          <Route exact path="/admin" element={<Admin />} />
        </Routes>
    </div>
  );
}

export default App;
