import React, { useState, useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { db } from "../firebase-config.js";
import "./Admin.scss";

// 오늘 데이터
const todayDate = new Date().toISOString().substr(0, 10);

// 입력 라인
function InputLine(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [trackingCode, setTrackingCode] = useState("");
  const [delCom, setDelCom] = useState("Kerry Express");
  const [date, setDate] = useState(todayDate);

  // phoneNumber 값
  const handlePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  // trackingCode 값
  const handleTrackingCode = (event) => {
    setTrackingCode(event.target.value);
  };

  // delCom 값
  const handleDelCom = (event) => {
    setDelCom(event.target.value);
  };

  // date 값
  const handleDate = (event) => {
    setDate(event.target.value);
  };

  // 각 값 변경(타이핑) 시 부모 컴포넌트로 전달
  useEffect(() => {
    props.onChange({ phoneNumber, trackingCode, delCom, date });
  }, [phoneNumber, trackingCode, delCom, date]);

  return (
    <div className="line">
      <div className="no col">
        <p>{props.no + 1}</p>
      </div>
      <div className="ph-no col">
        <input
          type="text"
          value={props.value.phoneNumber}
          onChange={handlePhoneNumber}
        />
      </div>
      <div className="track-no col">
        <input
          type="text"
          value={props.value.trackingCode}
          onChange={handleTrackingCode}
        />
      </div>
      <div className="del-comp col">
        <div className="radio-comp">
          <label>
            <input
              type="radio"
              value="Kerry Express"
              checked={delCom === "Kerry Express"}
              onChange={handleDelCom}
            />
            Kerry
          </label>
          <label>
            <input
              type="radio"
              value="Nim"
              checked={delCom === "Nim"}
              onChange={handleDelCom}
            />
            Nim
          </label>
          <label>
            <input
              type="radio"
              value="EMS"
              checked={delCom === "EMS"}
              onChange={handleDelCom}
            />
            EMS
          </label>
        </div>
      </div>
      <div className="date col">
        <input type="date" value={props.value.date} onChange={handleDate} />
      </div>
    </div>
  );
}

// =============================
// ========== 입력 팝업 ==========
// =============================

function AddTrackingPopup(props) {
  // 입력 라인 개수
  const [lineNumbers, setLineNumbers] = useState(1);

  // 전체 라인 입력된 데이터
  const [inputDatas, setInputDatas] = useState([]);

  function AddNumber() {
    // setLineNumbers(lineNumbers + 1);

    console.log(inputDatas);
    setInputDatas([
      ...inputDatas,
      {
        phoneNumber: "",
        trackingCode: "",
        delCom: "Kerry Express",
        date: todayDate,
      },
    ]);
  }

  function DelNumber() {
    // setLineNumbers(lineNumbers - 1);
    setInputDatas([...inputDatas].slice(0, -1));
  }

  // 각 컴포넌트의 입력값이 변했을 때 입력값을 전체 배열에 반영
  const handleInputChange = (index, value) => {
    setInputDatas((prevInputValues) => {
      const newInputValues = [...prevInputValues];
      newInputValues[index] = value;
      return newInputValues;
    });
  };

  useEffect(() => {
    setInputDatas([
      {
        phoneNumber: "",
        trackingCode: "",
        delCom: "Kerry Express",
        date: todayDate,
      },
    ]);
    console.log("초기화");
  }, []);

  // ================================
  // ================================
  // 입력 라인

  // ================================
  // 등록 기능
  // ================================

  function Register() {
    if (window.confirm("will you register?")) {
      // 최초 시도시에 한 전화번호에 코드 여러개 저장 안되는 부분 해결 위해 임시 전화번호 저장
      let tempPhoneNumbers = [];

      inputDatas.forEach((lineData) => {
        console.log(lineData.phoneNumber);

        // 전화번호가 입력되었다면 저장 진행
        if (lineData.phoneNumber !== "") {
          const collectionRef = db.collection("thaiDel");

          const docRef = collectionRef.doc(lineData.phoneNumber.toString());

          docRef.get().then((doc) => {
            const tempPhoneNumber = lineData.phoneNumber;

            // 01. 해당 전화번호가 이미 있으면
            if (doc.exists || tempPhoneNumbers.includes(tempPhoneNumber)) {
              // console.log('임시 저장' , tempPhoneNumber)
              // console.log('임시 저장' , tempPhoneNumbers.includes(lineData.phoneNumber))
              // console.log('임시 저장' , tempPhoneNumbers)
              // console.log('01로 진행')

              let convertedDate = firebase.firestore.Timestamp.fromDate(
                new Date(lineData.date)
              );

              // 해당 전화번호의 tracking에 map 추가

              // 해당 전화번호 Doc 정의
              const docRef = db
                .collection("thaiDel")
                .doc(lineData.phoneNumber.toString());

              // lineData 정보 업데이트
              docRef.update({
                trackings: firebase.firestore.FieldValue.arrayUnion({
                  code: lineData.trackingCode,
                  delCom: lineData.delCom,
                  update: convertedDate,
                }),
              });

              // 02. 해당 전화번호 없으면 해당 전화번호를 ID로 하는 문서 생성
            } else {
              // 해당 전화번호의 tracking에 map 추가

              let convertedDate = firebase.firestore.Timestamp.fromDate(
                new Date(lineData.date)
              );

              const docRef = db.collection("thaiDel");

              docRef.doc(lineData.phoneNumber).set({
                trackings: [
                  {
                    code: lineData.trackingCode,
                    delCom: lineData.delCom,
                    update: convertedDate,
                  },
                ],
              });

              tempPhoneNumbers.push(tempPhoneNumber);
            }
          });
        }
      });

      alert("register done !");
      props.popupToggle();

      setTimeout(() => {
        props.loadingList();
      }, 100);
    }
  }

  return (
    <div className="add-tracking-modal-dim">
      <div className="add-tracking-modal">
        <div className="input-lines">
          <div className="header-containor">
            <div className="no col">
              <p>no</p>
            </div>
            <div className="ph-no col">
              <p>📞 Phone number</p>
            </div>
            <div className="track-no col">
              <p>📦 Tracking Code</p>
            </div>
            <div className="del-comp col">
              <p>🚚 Delivery Company</p>
            </div>
            <div className="date col">
              <p>🗓️ Date</p>
            </div>
          </div>

          {inputDatas.map((lineData, index) => (
            <InputLine
              key={index}
              no={index}
              value={inputDatas[index]}
              onChange={(value) => handleInputChange(index, value)}
            />
          ))}
        </div>
        <div className="btn-area">
          <div className="popup-control">
            <button className="button cancel" onClick={props.popupToggle}>
              cancel
            </button>
            <button className="button register" onClick={Register}>
              register
            </button>
          </div>
          <div className="line-control">
            <button className="button delete-line" onClick={DelNumber}>
              - delete line
            </button>
            <button className="button add-line" onClick={AddNumber}>
              + add line
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// ==========================
// ========== 페이지 ==========
// ==========================

export default function Admin() {
  // 입력팝업 제공여부
  const [popupProvide, setPopupProvide] = useState(false);

  // 토글
  function popupToggle() {
    setPopupProvide(!popupProvide);
  }

  // 전화번호 목록
  const [phoneNumbers, setPhoneNumbers] = useState([]);

  //  트래킹 목록
  const [trackingList, setTrackingList] = useState([]);

  // 목록 불러오기
  function loadTrackingList() {
    db.collection("thaiDel")
      .get()
      .then((result) => {
        let tempPhoneNumberList = [];
        let tempTrackingList = [];

        result.forEach((doc) => {
          tempPhoneNumberList.push(doc.id);

          doc.data().trackings.map((item) => {
            const tempRecord = {
              phoneNumber: doc.id,
              deliveryCompany: item.delCom,
              trackingCode: item.code,
              date: item.update.toDate().toISOString().substr(0, 10),
            };

            tempTrackingList.push(tempRecord);
          });
        });

        // 최신 날짜 위로 오도록 재정렬
        tempTrackingList.sort((a, b) => new Date(b.date) - new Date(a.date));

        setPhoneNumbers(tempPhoneNumberList);
        setTrackingList(tempTrackingList);
      });
  }

  useEffect(() => {
    loadTrackingList();
  }, []);

  const columns = ["Phone number", "Delivery Company", "Tracking Code", "Date"];

  function trackingRecordDelete(phoneNumber, trackingCode) {
    // 해당 전화번호 문서로 진입
    const docRef = db.collection("thaiDel").doc(phoneNumber);

    let trackingIndex = "";

    // 해당 트래킹코드 인덱스 찾기
    docRef.get().then((doc) => {
      const dbTrackings = doc.data().trackings;

      // 인덱스 집어넣기
      trackingIndex = dbTrackings.findIndex((obj) => obj.code === trackingCode);
    });

    // 해당 레코드 지우기
    docRef.get().then((doc) => {
      const data = doc.data();
      const trackings = data.trackings; // replace with the name of your array field

      // Remove the item at index 2
      trackings.splice(trackingIndex, 1);

      // Update the document with the modified array
      return docRef.update({ trackings });
    });

    // 목록 다시 불러오기
    setTimeout(() => {
      loadTrackingList();
    }, 100);
  }

  function dataClearing() {
    if (window.confirm("clearing empty phonenumbers?")) {
      db.collection("thaiDel")
        .where("trackings", "==", []) // trackings 필드가 빈 배열인 도큐먼트 찾기
        .get()
        .then((querySnapshot) => {
          const batch = db.batch();
          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
          });
          return batch.commit();
        })
        .then(() => {
          console.log("삭제 완료");
        })
        .catch((error) => {
          console.error("삭제 에러:", error);
        });
    }
  }

  return (
    <div className="admin-containor">
      {popupProvide ? (
        <AddTrackingPopup
          phoneNumbers={phoneNumbers}
          popupToggle={popupToggle}
          loadingList={loadTrackingList}
        />
      ) : null}

      <div className="header-area">
        <h1>พัสดุ</h1>
        <div style={{ display: "flex", gap: "8px" }}>
          <button
            className="register-button"
            style={{ backgroundColor: "#DADDE2" }}
            onClick={dataClearing}
          >
            Data Clear
          </button>
          <button className="register-button" onClick={setPopupProvide}>
            Add Trackings
          </button>
        </div>
      </div>

      <table className="tracking-list-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}> {column} </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {trackingList.map((customer, index) => (
            <tr key={index}>
              <td className="col phone-number">{customer.phoneNumber}</td>
              <td className="col delivery-company">
                {customer.deliveryCompany}
              </td>
              <td className="col tracking-code">{customer.trackingCode}</td>
              <td className="col date">{customer.date}</td>
              <td>
                <button
                  onClick={() =>
                    trackingRecordDelete(
                      customer.phoneNumber,
                      customer.trackingCode
                    )
                  }
                >
                  delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
